import React, { useCallback, useState } from "react"
import { doc, setDoc, deleteDoc } from "firebase/firestore/lite"
import { fs, storage } from "../../../utils/firebase"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"

export default function EditTour({ tour }) {
  const [dates, setDates] = useState(tour?.dates)
  const [content, setContent] = useState(tour?.content)
  const [imgs, setImgs] = useState(tour?.imgs)
  const [isDeleted, setIsDeleted] = useState(false)

  const handleDelete = useCallback(() => {
    imgs.map(img => deleteObject(ref(storage, `${tour?.title}/${img.name}`)))
    deleteDoc(doc(fs, "tours", tour?.title)).then(() => setIsDeleted(true))
  }, [imgs])

  const handleMerge = useCallback(
    () =>
      setDoc(
        doc(fs, "tours", tour?.title),
        {
          dates: dates.sort(
            (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
          ),
          content,
          imgs,
        },
        { merge: true }
      ),
    [dates, content, imgs]
  )

  return isDeleted ? null : (
    <div>
      <b> {tour?.title}</b>
      <button onClick={handleDelete}>delete tour</button>
      <div>
        add date:{" "}
        <input
          name="date"
          type={"datetime-local"}
          onKeyUp={e => {
            const {
              target: { value },
            } = e
            if (e.key == "Enter") {
              setDates(prev => [
                {
                  time: value,
                  cost: "",
                  register: "",
                },
                ...prev,
              ])
            }
          }}
        />
      </div>
      {dates?.map((date, i) => (
        <div key={date.time}>
          {new Date(date.time).toLocaleString("en-GB")} --- cost:{" "}
          <input
            defaultValue={date.cost}
            onChange={e => {
              setDates(prev => {
                prev[i].cost = e.target.value
                return prev
              })
            }}
          />{" "}
          register:{" "}
          <input
            defaultValue={date.register}
            onChange={e =>
              setDates(prev => {
                prev[i].register = e.target.value
                return prev
              })
            }
          />{" "}
          <button
            onClick={() =>
              setTimeout(() => {
                setDates(prev => prev.filter(it => it.time != date.time))
              }, 10)
            }
          >
            delete date
          </button>
        </div>
      ))}
      <div>
        content{" "}
        <input value={content} onChange={e => setContent(e.target.value)} />
      </div>
      <div>
        <label htmlFor="add-imgs">add imgs</label>
        <input
          multiple
          onChange={e => {
            const imgs = []
            const promises = [...e.target.files].map(
              f =>
                uploadBytes(ref(storage, `${tour?.title}/${f.name}`), f).then(
                  s =>
                    getDownloadURL(s.ref).then(url =>
                      imgs.push({ url, name: f.name, priority: 1 })
                    )
                )
              // .catch(err => console.log({ err }))
            )
            Promise.all(promises).then(() =>
              setImgs(prev => [...prev, ...imgs])
            )
          }}
          name="add-imgs"
          type={"file"}
          accept="image/*"
        />
      </div>
      <div>
        {imgs?.map((img, i) => (
          <div style={{ display: "flex", flexDirection: "row" }} key={img.url}>
            <img
              style={{ height: "200px", width: "200px" }}
              src={img.url}
              alt={img.name}
            />{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                priority:{" "}
                <input
                  type={"number"}
                  defaultValue={img.priority}
                  onChange={e =>
                    setImgs(prev => {
                      prev[i].priority = e.target.value
                      return prev
                    })
                  }
                />
              </div>
              <button
                onClick={() => {
                  deleteObject(ref(storage, `${tour?.title}/${img.name}`)).then(
                    () => setImgs(prev => prev.filter(it => it.url != img.url))
                  )
                  // .catch(err => console.log({ err }))
                }}
              >
                delete img
              </button>{" "}
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleMerge}>save</button>
      <br />
      <br />
    </div>
  )
}
